/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of content block body scale.
 */

export const ContentBlockBodyScale = {
  LARGE: "LARGE",
};
