/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of heading theme.
 */

export const HeadingTheme = {
  BLUE: "BLUE",
  ORANGE: "ORANGE",
};
