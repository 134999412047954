/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of scale classname.
 */

export const ScaleClassname = {
  MEDIUM: "scale___m",
  LARGE: "scale___l",
};
