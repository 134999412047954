/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of content block scale.
 */

export const ContentBlockScale = {
  LARGE: "LARGE",
};
