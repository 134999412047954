/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of heading scale.
 */

export const HeadingScale = {
  LARGE: "LARGE",
  MEDIUM: "MEDIUM",
  SMALL: "SMALL",
};
