/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of scale.
 */

export const Scale = {
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
};
