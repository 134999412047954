/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of content block theme.
 */

export const ContentBlockTheme = {
  OFF_WHITE: "OFF_WHITE",
};
