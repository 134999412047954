/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of content block body classname.
 */

export const ContentBlockBodyClassname = {
  LARGE: "content__body___l",
};
